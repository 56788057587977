<template>
	<v-dialog v-model="localValue" :width="width" :persistent="loading || persistent">
		<v-card>
			<v-card-title class="text-h6">
				<template v-if="!$slots['title'] && !$scopedSlots['title']">
					<v-spacer></v-spacer>
					{{title}}
					<v-spacer></v-spacer>
				</template>
				<slot name="title"></slot>
			</v-card-title>

			<v-card-text>
				<slot></slot>
			</v-card-text>

			<!-- <v-divider></v-divider> -->

			<v-card-actions v-if="!hideActions">
				<v-btn
					v-if="!hideConfirmBtn"
					color="primary"
					text
					class="text-body-1"
					:loading="loading"
					:disabled="loading || disabled"
					@click="$emit('confirm')"
				>
					{{confirmText}}
				</v-btn>
				<v-btn
					v-if="!hideCancelBtn"
					text
					:disabled="loading"
					@click="$emit('cancel'); localValue = false"
				>
					{{cancelText}}
				</v-btn>
				<v-spacer></v-spacer>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		value: { type: Boolean, default: false },
		title: { type: String },
		loading: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		persistent: { type: Boolean, default: false },
		'hide-actions': { type: Boolean, default: false },
		'hide-confirm-btn': { type: Boolean, default: false },
		'hide-cancel-btn': { type: Boolean, default: false },
		confirmText: { type: String, default: 'تأكيد' },
		cancelText: { type: String, default: 'إلغاء' },
		width: { type: [Number, String], default: 500 },
	},

	computed: {
		localValue: {
			get() {
				return this.value;
			},
			set(newVal) {
				return this.$emit('input', newVal);
			}
		}
	}
};
</script>

<style>
.v-dialog {
	/* border-radius: 10px; */
}
</style>