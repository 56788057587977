<template>
	<div>
		<h1 class="primary--text text-center text-h6 mb-4">العمليات</h1>
		
		<div class="d-flex flex-wrap justify-space-between">
			<!-- average -->
			<div class="result-chip">
				<div class="result-chip-content">
					<v-progress-circular :indeterminate="!result" color="success" width="5" size="50" :rotate="!result ? 0 : '-90'" :value="result ? result.average : 0">
						{{ result ? result.average + '%' : null }}
					</v-progress-circular>
					<v-spacer></v-spacer>
					<span class="me-6">المعدل</span>
					<v-spacer></v-spacer>
				</div>
			</div>
			
			<!-- Absenteeism rate -->
			<div class="result-chip">
				<div class="result-chip-content">
					<v-progress-circular :indeterminate="!result" color="error lighten-2" width="5" size="50" :rotate="!result ? 0 : '-90'" :value="result ? result.absence_ratio : 0">
						{{ result ? result.absence_ratio + '%' : null }}
					</v-progress-circular>
					<v-spacer></v-spacer>
					<span class="me-6">نسبة الغياب</span>
					<v-spacer></v-spacer>
				</div>
			</div>

			<!-- certificate -->
			<div class="result-chip">
				<div class="result-chip-content">
					<v-progress-circular :indeterminate="!result"
						:color="result && result.certificate === 1 ? 'success' : 'primary lighten-2'"
						width="2"
						size="45"
						:rotate="!result ? 0 : '-90'"
						value="100"
					>
						<v-icon color="success" v-if="result && result.certificate === 1">mdi-check</v-icon>
						<v-icon color="primary lighten-2" v-else>mdi-close</v-icon>
					</v-progress-circular>
					<v-spacer></v-spacer>
					<span class="d-inline-flex flex-column align-center me-6">
						<span>الشهادة</span>
						<span
							:class="result && result.certificate === 1 ? 'success--text' : 'primary--text lighten-2'"
							class="text-caption"
						>
							{{result && result.certificate === 1 ? 'جاهزة' : 'غير جاهزة'}}
						</span>
					</span>
					<v-spacer></v-spacer>
				</div>
			</div>

			<!-- notes -->
			<div class="result-chip">
				<div class="result-chip-content">
					<v-progress-circular :indeterminate="!result" color="grey darken-1" width="2" size="45" :rotate="!result ? 0 : '-90'" value="100">
						<v-icon color="grey darken-1">mdi-information-variant</v-icon>
					</v-progress-circular>
					<v-spacer></v-spacer>
					<span v-if="result && result.notes.replace(/\s/g, '').length" class="text-caption me-6">{{result.notes}}</span>
					<span v-else class="text-caption me-6">لا يوجد ملاحظات</span>
					<v-spacer></v-spacer>
				</div>
			</div>
		</div>
		<p class="text-center text-body-2 primary--text mt-5 mb-0">
			آخر عملية تحديث: 
			<span dir="auto">{{ result ? moment(result.updated_at) : '-' }}</span>
		</p>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment'
export default {
	name: 'Results',

	computed: {
		result() {
			return this.coursesData.find(c => c.ids === Number(this.$route.params.id))?.operations[0] ?? null;
		},

		...mapState({
			coursesData: state => state.student.coursesData,
		})	
	},

	methods: {
		moment(date) {
			return moment(date).format('YYYY-MM-DD, h:mm a')
		},
	},
	
	metaInfo: {
		title: 'Results',
		titleTemplate: '%s | New Horizons'
	}
}
</script>

<style lang="scss" scoped>
.result {
	&-chip {
		margin: 0 4px;
		width: 47%;

		cursor: default;

		&:nth-of-type(1),
		&:nth-of-type(2) {
			margin-bottom: 1.4rem;
		} 
		@media screen and (max-width: 400px){
			&:not(&:last-of-type) {
				margin-bottom: 1.4rem;
			}
			width: 100%;
		}

		&-content {
			display: flex;
			align-items: center;
			flex-direction: row;
			border-radius: 27px;
			border: 1px solid #d8d8d8;

			width: 60px;
			animation: fill-width .5s cubic-bezier(.32,.35,.54,1) .1s forwards;
			@keyframes fill-width {
				from {
					width: 40px;
				}
				to {
					width: 100%;
				}
			}

			& > * {
				display: hidden;
				visibility: hidden;
				opacity: 0;
				animation: fade-in .3s cubic-bezier(.32,.35,.54,1) .7s forwards;
				@keyframes fade-in {
					from {
						display: hidden;
						visibility: hidden;
						opacity: 0;
					}
					to {
						display: inline-flex;
						visibility: visible;
						opacity: 2;
					}
				}
			}
		}
	}
}
</style>