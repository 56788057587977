<template>
	<div>
		<!-- dialogs -->
		<!-- user name and image dialog -->
		<bee-dialog
			v-model="dialogs.userNameImage"
			width="170"
			title="صورة الحساب"
			:loading="dialogs.loading"
			@confirm="saveImage"
		>
			<v-row class="justify-center mt-1">
				<v-col cols="12" class="pb-0">
					<div
						v-ripple
						ref="displayImage"
						class="display-image"
						:style="`background-image: url(${profile.pic_link})`"
					>
						<v-file-input
							v-model="user.image"
							hide-details
							hide-input
							prepend-icon="mdi-pencil"
							class="mt-0 pt-0 justify-center ms-2"
							accept="image/*"
						></v-file-input>
					</div>
				</v-col>
			</v-row>
		</bee-dialog>

		<!-- user -->
		<bee-dialog
			v-model="dialogs.mainInfo"
			title="المعلومات الأساسية"
			:loading="dialogs.loading"
			@confirm="updateProfile('main-info')"
		>
			<v-row class="mt-1">
				<v-col md="4" :cols="$vuetify.breakpoint.width > 400 ? 6 : 12" class="py-1">
					<v-menu
						ref="menu"
						v-model="menu"
						:close-on-content-click="false"
						transition="scale-transition"
						offset-y
						min-width="auto"
					>
						<template v-slot:activator="{ on, attrs }">
						<v-text-field
							v-model="mainInfo.date"
							label="تاريخ الميلاد"
							prepend-icon="mdi-calendar"
							readonly
							hide-details
							v-bind="attrs"
							v-on="on"
						></v-text-field>
						</template>
						<v-date-picker
							v-model="mainInfo.date"
							:active-picker.sync="activePicker"
							:max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
							min="1950-01-01"
							@change="save"
						></v-date-picker>
					</v-menu>
				</v-col>
				<v-col md="4" :cols="$vuetify.breakpoint.width > 400 ? 6 : 12" class="py-1">
					<v-text-field v-model="mainInfo.job" hide-details label="العمل"></v-text-field>
				</v-col>
				<v-col md="4" :cols="$vuetify.breakpoint.width > 400 ? 6 : 12" class="py-1">
					<v-text-field v-model="mainInfo.company" hide-details label="الشركة"></v-text-field>
				</v-col>
				<v-col md="4" :cols="$vuetify.breakpoint.width > 400 ? 6 : 12" class="py-1">
					<v-text-field v-model="mainInfo.university" hide-details label="الجامعة"></v-text-field>
				</v-col>
				<v-col md="4" :cols="$vuetify.breakpoint.width > 400 ? 6 : 12" class="py-1">
					<v-text-field v-model="mainInfo.college" hide-details label="الكلية"></v-text-field>
				</v-col>
				<v-col md="4" :cols="$vuetify.breakpoint.width > 400 ? 6 : 12" class="py-1">
					<v-text-field v-model="mainInfo.department" hide-details label="الاختصاص"></v-text-field>
				</v-col>
				<v-col sm="6" cols="12" class="flex py-1">
					<v-radio-group row v-model="mainInfo.gender" hide-details>
						<span class="primary--text">الجنس:</span>
						<v-radio
							v-for="item in [{label: 'أنثى', value: 0}, {label: 'ذكر', value: 1}]"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
		</bee-dialog>

		<!-- info -->
		<bee-dialog
			v-model="dialogs.addressesInfo"
			width="350"
			title="معلومات الوصول المحلي"
			:loading="dialogs.loading"
			@confirm="updateProfile('adresses')"
		>
			<v-row class="justify-center mt-1">
				<v-col cols="12" class="py-1">
					<v-text-field v-model="addressesInfo.address" hide-details label="عنوان السكن"></v-text-field>
				</v-col>
				<v-col cols="12" class="py-1">
					<v-text-field v-model="addressesInfo.facebook" hide-details label="فيسبوك"></v-text-field>
				</v-col>
				<v-col cols="12" class="py-1">
					<v-text-field v-model="addressesInfo.email" hide-details label="الإيميل"></v-text-field>
				</v-col>
			</v-row>
		</bee-dialog>

		<!-- password -->
		<bee-dialog
			v-model="dialogs.password"
			:loading="dialogs.loading"
			width="350"
			title="تغيير كلمة المرور"
			@confirm="changePassword"
		>
			<v-form ref="changePasswordForm">
				<v-row class="justify-center mt-1">
					<v-col cols="12" class="py-1">
						<v-text-field
							v-model="password.old"
							label="كلمة المرور القديمة"
							type="password"
							:rules="[val => password.old === oldSavedPassword || 'كلمة السر غير صحيحة']"
						></v-text-field>
					</v-col>
					<v-col cols="12" class="py-1">
						<v-text-field
							v-model="password.value"
							label="كلمة المرور الجديدة"
							type="password"
							:rules="rules.password"
						></v-text-field>
					</v-col>
					<v-col cols="12" class="py-1">
						<v-text-field
							v-model="password.confirm"
							label="تأكيد كلمة المرور"
							type="password"
							:rules="[val => password.value === password.confirm || 'كلمة السر غير مطابقة']"
						></v-text-field>
					</v-col>
				</v-row>
			</v-form>
		</bee-dialog>

		<!-- mobile numbers -->
		<bee-dialog
			v-model="dialogs.mobileNumbers"
			:loading="dialogs.loading"
			:hide-actions="true"
			width="300"
			title="أرقام الهواتف"
		>
			<template v-slot:title>
				<v-spacer></v-spacer>	
				أرقام الهواتف
				<v-spacer></v-spacer>
				<v-btn v-if="mobileNumberCounter < 3" icon text :disabled="dialogs.loading" @click="addMobileNumber">
					<v-icon>mdi-plus-circle-outline</v-icon>
				</v-btn>
			</template>

			<v-row class="justify-center mt-1">
				<v-col
					v-for="(number, index) in mobileNumbers"
					:key="index"
					cols="12"
					class="d-flex align-center py-1"
				>
					<v-text-field
						v-model="number.value"
						:ref="`input-number-${index}`"
						:label="`رقم الهاتف ${index + 1}`"
						:rules="editNumberIndex === index || number.isNew ? rules.mobile : []"
						:disabled="!number.isNew && index !== editNumberIndex"
						dir="auto"
						class="me-1"
					></v-text-field>

					<template v-if="deleteNumberIndex !== index && (!dialogs.loading || editNumberIndex !== index)">
						<v-btn
							v-if="!number.isNew && (!(editNumberIndex+1) || index !== editNumberIndex)"
							icon
							text
							:disabled="dialogs.loading"
							@click="editNumberIndex = index; $nextTick(() => $refs[`input-number-${index}`][0].focus())"
						>
							<v-icon>mdi-pencil</v-icon>
						</v-btn>
						<v-btn
							v-if="index === editNumberIndex || number.isNew"
							icon
							text
							:disabled="dialogs.loading"
							@click="updateNumber(number.isNew ? 'add' : 'edit', index)"
						>
							<v-icon>mdi-check-circle-outline</v-icon>
						</v-btn>
						<v-btn
							v-if="!number.isNew && (mobileNumberCounter > 1 )"
							icon
							text
							:disabled="dialogs.loading"
							@click="deleteNumberIndex = index; updateNumber('delete', index)"
						>
							<v-icon>mdi-delete</v-icon>
						</v-btn>
						<v-btn v-if="number.isNew" icon text @click="removeMobileNumber(number.index)">
							<v-icon>mdi-minus-circle-outline</v-icon>
						</v-btn>
					</template>
					<template v-else>
						<v-progress-circular size="20" width="2" indeterminate class="ms-3 me-9"></v-progress-circular>
					</template>
				</v-col>
			</v-row>
		</bee-dialog>
		
		<!-- page content -->
		<!-- user name and image -->
		<v-toolbar
			v-ripple="!hideEdit"
			elevation="2"
			height="80"
			color="primary"
			dark
			class="d-flex justify-center align-center mb-3 shaped-top"
			:class="{'cursor-pointer': !hideEdit}"
			@click="navLoading || hideEdit ? null : dialogs.userNameImage = true"
		>
			<v-avatar  :size="$vuetify.breakpoint.width > 430 ? 70 : 60" color="secondary" class="me-4">
				<v-img v-if="profile.pic_link" :src="profile.pic_link" :alt="profile.name_en"></v-img>
				<img v-else src="../assets/user.png">
			</v-avatar>
			<div class="d-flex flex-column">
				<p
					class="font-weight-bold mb-0"
					:class="{
						'text-body-1': $vuetify.breakpoint.width > 430,
						'text-body-2': $vuetify.breakpoint.width <= 430,
					}"
				>
					{{profile.name_ar ? profile.name_ar : '-' }}
				</p>
				<p
					class="font-weight-bold mb-0"
					:class="{
						'text-body-1': $vuetify.breakpoint.width > 430,
						'text-body-2': $vuetify.breakpoint.width <= 430,
					}"
				>
					{{profile.name_en ? profile.name_en: '-' }}
				</p>
			</div>
		</v-toolbar>

		<!-- info -->
		<v-expansion-panels focusable popout>
			<!-- main info -->
			<v-expansion-panel active-class="mt-0" class="mb-3">
				<v-expansion-panel-header>
					<div>
						<v-btn v-if="!hideEdit" icon small :disabled="navLoading" @click="dialogs.mainInfo = true"><v-icon>mdi-pencil</v-icon></v-btn>
					</div>
					<span class="flex-grow-1">المعلومات الأساسية</span>
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<div class="d-flex flex-wrap mt-1">
						<div class="details-container py-1">
							<span class="primary--text me-1">تاريخ الميلاد:</span>
							<v-spacer></v-spacer>
							{{profile.birthday && profile.birthday !== -1 ? moment(profile.birthday).format('YYYY-MM-DD') : '-'}}
							<v-spacer></v-spacer>
						</div>
						<div class="details-container py-1">
							<span class="primary--text me-1">العمل:</span>
							<v-spacer></v-spacer>
							{{profile.work ? profile.work : '-'}}
							<v-spacer></v-spacer>
						</div>
						<div class="details-container py-1">
							<span class="primary--text me-1">الشركة:</span>
							<v-spacer></v-spacer>
							{{profile.company ? profile.company : '-'}}
							<v-spacer></v-spacer>
						</div>
						<div class="details-container py-1">
							<span class="primary--text me-1">الجامعة:</span>
							<v-spacer></v-spacer>
							{{profile.university ? profile.university : '-'}}
							<v-spacer></v-spacer>
						</div>
						<div class="details-container py-1">
							<span class="primary--text me-1">الكلية:</span>
							<v-spacer></v-spacer>
							{{profile.faculity ? profile.faculity : '-'}}
							<v-spacer></v-spacer>
						</div>
						<div class="details-container py-1">
							<span class="primary--text me-1">الاختصاص:</span>
							<v-spacer></v-spacer>
							{{profile.specialty ? profile.specialty : '-'}}
							<v-spacer></v-spacer>
						</div>
						<div class="details-container py-1">
							<span class="primary--text me-1">الجنس:</span>
							<v-spacer></v-spacer>
							{{profile.gender !== -1 ? (profile.gender === 0 ? 'أنثى' : 'ذكر') : '-'}}
							<v-spacer></v-spacer>
						</div>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>

			<!-- address info -->
			<v-expansion-panel active-class="mt-0" class="mb-3">
				<v-expansion-panel-header>
					<div>
						<v-btn v-if="!hideEdit" icon small :disabled="navLoading" @click="dialogs.addressesInfo = true"><v-icon>mdi-pencil</v-icon></v-btn>
					</div>
					<span class="flex-grow-1">معلومات الوصول المحلي</span>
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<div class="d-flex flex-md-row flex-column justify-space-around mt-1">
						<div class="d-flex py-1">
							<span class="primary--text me-1">عنوان السكن:</span>
							<v-spacer></v-spacer>
							{{profile.address ? profile.address : '-'}}
							<v-spacer></v-spacer>
						</div>
						<div class="d-flex py-1">
							<span class="primary--text me-1">فيسبوك:</span>
							<v-spacer></v-spacer>
							{{profile.facebook ? profile.facebook : '-'}}
							<v-spacer></v-spacer>
						</div>
						<div class="d-flex py-1">
							<span class="primary--text me-1">الإيميل:</span>
							<v-spacer></v-spacer>
							{{profile.email ? profile.email : '-'}}
							<v-spacer></v-spacer>
						</div>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>

			<!-- account info -->
			<v-expansion-panel active-class="mt-0" class="mb-3">
				<v-expansion-panel-header>
					<div>
						<v-btn v-if="!hideEdit" icon small :disabled="navLoading" @click="dialogs.password = true"><v-icon>mdi-pencil</v-icon></v-btn>
					</div>
					<span class="flex-grow-1">معلومات الحساب</span>
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<div class="d-flex flex-wrap justify-space-around mt-1">
						<div class="d-flex py-1">
							<span class="primary--text me-1">رقم الطالب:</span>
							<v-spacer></v-spacer>
							{{profile.nh_id}}
							<v-spacer></v-spacer>
						</div>
						<div class="d-flex py-1">
							<span class="primary--text me-1">كلمة المرور:</span>
							<v-spacer></v-spacer>
							••••••••••••
							<v-spacer></v-spacer>
						</div>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>

			<!-- mobile numbers -->
			<v-expansion-panel active-class="mt-0" class="mb-3">
				<v-expansion-panel-header>
					<div>
						<v-btn v-if="!hideEdit" icon small :disabled="navLoading" @click="dialogs.mobileNumbers = true"><v-icon>mdi-pencil</v-icon></v-btn>
					</div>
					<span class="flex-grow-1">أرقام الجوال</span>
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<div class="d-flex d-flex flex-sm-row flex-column justify-space-around mt-1">
						<div v-for="(number, index) in userNumbers" :key="number.ids" class="d-flex py-1">
							<span class="primary--text me-1">رقم {{index + 1}}:</span>
							<v-spacer></v-spacer>
							{{number.number}}
							<v-spacer></v-spacer>
						</div>
						<p class="text-center mb-0" v-if="userNumbers.length === 0">لا يوجد</p>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>

		<div class="shaped-bottom elevation-3 primary white--text" style="height: 50px"></div>
	</div>
</template>

<script>
import rules from '../validation rules'
import moment from 'moment'
import { mapState } from 'vuex';
export default {
	name: 'UserInfo',

	props: {
		navLoading: { type: Boolean }
	},

	data: () => ({
		// helpers
		rules,
		loading: false,
		activePicker: null,
		menu: false,
		dialogs: {
			userNameImage: false,
			mainInfo: false,
			addressesInfo: false,
			password: false,
			mobileNumbers: false,
			loading: false
		},
		mobileNumberCounter: 0,
		
		hideEdit: true,

		// data
		user: { image: null },
		mainInfo: {
			date: null,
			job: null,
			company: null,
			university: null,
			college: null,
			department: null,
			gender: null
		},
		addressesInfo: {
			address: null,
			facebook: null,
			email: null
		},
		password: {
			old: null,
			value: null,
			confirm: null
		},

		editNumberIndex: null,
		deleteNumberIndex: null,
		mobileNumbers: []
	}),

	watch: {
		'user.image'(val) {
			if (val) {
				const reader = new FileReader();
				reader.readAsDataURL(val);
				reader.addEventListener('load', () => {
					const uploadedImage = reader.result;
					this.$refs.displayImage.style.backgroundImage = `url(${uploadedImage})`;
				});
			} else {
				this.$refs.displayImage.style.backgroundImage = null;	
			}
		},
		
		authenticated(val) {
			if (val) this.fetchUserNumbers()
		},

		'dialogs.mainInfo'(val) {
			if (val) {
				this.mainInfo.date = this.profile.birthday !== -1 ? moment(this.profile.birthday).format('YYYY-MM-DD') : null;
				this.mainInfo.job = this.profile.work;
				this.mainInfo.company = this.profile.company;
				this.mainInfo.university = this.profile.university;
				this.mainInfo.college = this.profile.faculity;
				this.mainInfo.department = this.profile.specialty;
				this.mainInfo.gender = this.profile.gender;
			}
		},
		'dialogs.addressesInfo'(val) {
			if (val) {
				this.addressesInfo.address = this.profile.address;
				this.addressesInfo.facebook = this.profile.facebook;
				this.addressesInfo.email = this.profile.email;
			}
		},
		'dialogs.mobileNumbers'(val) {
			if (val) {
				this.mobileNumberCounter = 0;
				this.mobileNumbers = [];
				this.userNumbers.forEach(item => {
					this.mobileNumbers.push({
						index: this.mobileNumberCounter++,
						id: item.ids,
						isNew: false,
						value: item.number
					})
				})
			}
		},

		'dialogs.userNameImage'(val) {
			if (val) {
				if (this.$refs.displayImage && this.profile.pic_link) {
					this.$refs.displayImage.style.backgroundImage = `url(${this.profile.pic_link})`;
				} else {
					this.user.image = null;
				}
			}
		},
		
		menu (val) {
			val && setTimeout(() => (this.activePicker = 'YEAR'))
		}
	},

	computed: {
		...mapState({
			authenticated: state => state.auth.authenticated,
			userNumbers: state => state.student.numbers,
			profile: state => state.student.profile,
		}),

		oldSavedPassword() {
			return JSON.parse(localStorage.auth).password;
		}
	},

	methods: {
		save (date) {
			this.$refs.menu.save(date)
		},

		addMobileNumber() {
			this.mobileNumbers.push({
				index: this.mobileNumberCounter++,
				id: null,
				isNew: true,
				value: null
			})
		},

		removeMobileNumber(index) {
			this.mobileNumberCounter--;
			this.mobileNumbers.splice(index, 1);
			this.mobileNumbers.forEach(number => {
				if (number.index > index) {
					number.index--;
				}
			})
		},
		
		// dialogs
		changePassword() {
			if (this.$refs.changePasswordForm.validate()) {
				this.dialogs.loading = true;
				this.$store.dispatch('student/updatePassword', {
					password: this.password.value
				}).then((data) => {
					if (Number(data.status) === 200) {
						this.$emit('snackbar:on', 'تم تحديث كلمة المرور بنجاح');
						this.dialogs.password = false;
						this.password.value = null;
						this.password.confirm = null;
					} else {
						this.$emit('snackbar:on', 'حدث خطأ ما');
					}
				}).finally(() => {
					this.dialogs.loading = false;
				})
			}
		},

		updateProfile(dataUpdateType) {
			this.dialogs.loading = true;
			let postData;
			/* eslint-disable indent */
			switch (dataUpdateType) {
				case 'main-info': {
					postData = {
						birthday: moment(this.mainInfo.date).unix(),
						work: this.mainInfo.job,
						company: this.mainInfo.company,
						university: this.mainInfo.university,
						faculty: this.mainInfo.college,
						speciality: this.mainInfo.department,
						gender: this.mainInfo.gender,

						address: this.profile.address,
						email: this.profile.email,
						facebook: this.profile.facebook,
						fixed_phone: null,
					}
					break;
				}
				case 'adresses': {
					postData = {
						birthday: this.profile.birthday,
						work: this.profile.work,
						company: this.profile.company,
						university: this.profile.university,
						faculty: this.profile.faculty,
						speciality: this.profile.speciality,
						gender: this.profile.gender,

						address: this.addressesInfo.address,
						facebook: this.addressesInfo.facebook,
						email: this.addressesInfo.email,
						fixed_phone: null,
					}
					break;
				}
			}
			/* eslint-enable indent */

			this.$store.dispatch('student/updateProfile', postData).then(() => {
				this.dialogs.mainInfo = false;
				this.dialogs.addressesInfo = false;
				
				this.mainInfo.date = null;
				this.mainInfo.job = null;
				this.mainInfo.company = null;
				this.mainInfo.university = null;
				this.mainInfo.college = null;
				this.mainInfo.department = null;
				this.mainInfo.gender = null;

				this.addressesInfo.address = null;
				this.addressesInfo.facebook = null;
				this.addressesInfo.email = null;

				this.$emit('snackbar:on', 'تم تحديث المعلومات بنجاح');
				
				this.$emit('nav-loader:on');
				this.$store.dispatch('student/fetchProfile').finally(() => {
					this.$emit('nav-loader:off');
				})
			}).finally(() => {
				this.dialogs.loading = false;
			})
		},

		updateNumber(action, numberIndex) {
			let actionName;
			const numberValue = this.mobileNumbers.find(c => c.index === numberIndex).value;
			const numberId = this.mobileNumbers.find(c => c.index === numberIndex).id;
			if (rules.mobile[0](numberValue) === true || action === 'delete') {
				/* eslint-disable indent */
				switch (action) {
					case 'add' : actionName = 'createNumber'; break;
					case 'edit' : actionName = 'updateNumber'; break;
					case 'delete' : actionName = 'deleteNumber'; break;
				}
				/* eslint-enable indent */
				this.dialogs.loading = true;

				this.$store.dispatch(`student/${actionName}`, {
					number: numberValue,
					numberId: numberId,
					newNumber: numberValue
				}).then((data) => {
					if (Number(data.status) === 200) {
						this.$emit('snackbar:on', 'سيتم مراجعة طلبك من المركز');
						this.dialogs.mobileNumbers = false;

						this.$emit('nav-loader:on');
						this.$store.dispatch('student/fetchNumbers').finally(() => {
							this.$emit('nav-loader:off');
						})
					} else {
						this.$emit('snackbar:on', 'حدث خطأ ما');
					}
				}).finally(() => {
					this.editNumberIndex = null;
					this.deleteNumberIndex = null;
					this.dialogs.loading = false;
				})
			}
		},

		saveImage() {
			this.dialogs.loading = true;
			this.$store.dispatch('student/uploadImage', { image: this.user.image }).then((data) => {
				if (Number(data.status) === 200) {
					this.dialogs.userNameImage = false;
					this.$emit('snackbar:on', 'تم تحديث الصورة');
					this.$emit('nav-loader:on');
					this.$store.dispatch('student/fetchProfile').finally(() => {
						this.$emit('nav-loader:off');
					})
				} else {
					this.$emit('snackbar:on', 'حدث خطأ ما');
				}
			}).finally(() => {
				this.dialogs.loading = false;
			})
		},

		// data
		fetchUserNumbers() {
			if (this.userNumbers.length === 0) {
				this.loading = true;
				this.$emit('nav-loader:on')
				this.$store.dispatch('student/fetchNumbers').finally(() => {
					this.loading = false;
					this.$emit('nav-loader:off')
				})
			}
		},

		// helpers
		moment,
	},

	created() {
		if (this.authenticated) {
			this.fetchUserNumbers()
		}
	},

	metaInfo: {
		title: 'User info',
		titleTemplate: '%s | New Horizons'
	}
}
</script>

<style lang='scss' scoped>
.display-image {
	width: 100px;
	height: 100px;
	margin: 0 auto;
	margin-bottom: 8px;
	border: 1px solid #d8d8d8;
	border-radius: 50px;
	display: flex;
	justify-content: center;
	align-items: center;

	background-position: top;
    background-size: cover;
    background-repeat: no-repeat;

	&:first-child {
		transform: scale(1.3);
	}
}

.details-container {
	width: 100%;
	display: flex;
	@media screen and (min-width: 430px) {
		width: 50%;
	}
}
</style>