import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueMeta from 'vue-meta'
import axios from 'axios'
import { BASE_API_URL } from './constants'

import BeeDialog from './components/BeeDialog.vue'

Vue.use(VueMeta)
Vue.component('bee-dialog', BeeDialog);

window.axios = axios.create({
	baseURL: BASE_API_URL,
	headers: { accept: 'application/json' }
})

window.fixedPostData = null;

if ('auth' in localStorage) {
	const auth = JSON.parse(localStorage.getItem('auth'));
	store.dispatch('auth/login', auth)
		.then((data) => {
			if (data.status >= 200 && data.status < 300) {
				store.dispatch('student/fetchProfile')
			} else {
				store.dispatch('auth/logout');
				router.replace({ name: 'login' });
			}
		})
		.catch(() => {
			store.dispatch('auth/logout');
			router.replace({ name: 'login' });
		})
} else {
	router.replace({ name: 'login' });
}

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
	if (to.name !== 'login' && !('auth' in localStorage)) next({ name: 'login', replace: true });
	else if (to.name === 'login' && ('auth' in localStorage)) next({ name: 'home', replace: true });
	else next()
})
new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount('#app')
