<template>
	<div class="breadcrumb grey lighten-4">
		<v-chip-group show-arrows>
			<v-chip
				v-for="(item, index) in items"
				:key="index"
				:to="item.to"
				active-class="primary white--text"
				exact-active-class="primary white--text"
				class="breadcrumb-item px-8 elevation-2"
				color="white"
			>
				{{item.text}}
			</v-chip>
        </v-chip-group>
	</div>
</template>

<script>
export default {
	props: {
		items: { type: Array }
	}
}
</script>

<style lang="scss">
.breadcrumb {
	display: flex;

	position: absolute;
	top: 0;
	left: 0;
	width: 100%;

	&-item {
		border-radius: 20px 3px !important;
	}
}
</style>