<template>
	<div>
		<h1 class="primary--text text-center text-h6 mb-4">دورات مسجل بها</h1>
		<transition name="loading" mode="out-in">
			<v-expansion-panels v-if="coursesData.length > 0" :multiple="$vuetify.breakpoint.smAndUp">
				<v-row class="mt-0">
					<v-col
						v-for="(course, i) in coursesData"
						:key="i"
						sm="6"
						cols="12"
						class="in-animation py-0 mb-1"
						:class="{'mb-3': $vuetify.breakpoint.smAndUp}"
					>
						<v-expansion-panel :active-class="!$vuetify.breakpoint.smAndUp ? 'my-4' : null" class="rounded">
							<v-expansion-panel-header>
								{{course.title}}
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<div class="d-flex justify-space-between">
									<v-btn
										:to="{ name: 'results', params: { id: course.ids } }"
										color="success"
										dark
										width="45%"
										class="text-body-1"
									>
										<v-icon class="me-2">mdi-clipboard-text-search-outline</v-icon>
										العمليات
									</v-btn>
									<v-btn
										:to="{ name: 'exams', params: { id: course.ids } }"
										color="grey darken-2"
										dark
										width="45%"
										class="text-body-1"
									>
										<v-icon class="me-2">mdi-file-document-edit-outline</v-icon>
										الامتحانات
									</v-btn>
								</div>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-col>
				</v-row>
			</v-expansion-panels>

			<div v-else class="d-flex flex-column justify-center align-center mt-15" :class="{'pulse': navLoading}">
				<v-img
					min-width="150"
					width="35%"
					max-width="250"
					src="@/assets/online-education.png"
					transition="scale-transition"
				></v-img>
				<p v-if="!navLoading" class="mt-3 mb-0 text-subtitle-1 font-weight-bold">لا يوجد دورات</p>
			</div>
		</transition>
	</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
	name: 'MyCoureses',

	props: {
		navLoading: { type: Boolean }
	},

	computed: {
		...mapState({
			coursesData: state => state.student.coursesData
		})
	},
	
	metaInfo: {
		title: 'My coureses',
		titleTemplate: '%s | New Horizons'
	},
}
</script>

<style>
</style>