<template>
	<div class="mt-14">
		<bread-crumb :items="breadCrumbItems"></bread-crumb>
		
		<!-- btns -->
		<div class="text-center">
			<v-btn
				v-for="n in 10"
				:key="n"
				:width="$vuetify.breakpoint.smAndDown ? '80%' : '31%'"
				class="in-animation text-body-1 mb-3"
				:class="{'mx-3': $vuetify.breakpoint.smAndUp}"
			>
				اسم دورة {{n}} في المركز
			</v-btn>
		</div>
	</div>
</template>

<script>
import BreadCrumb from '../../components/BreadCrumb.vue'
export default {
	name: 'Courses',
	components: {
		BreadCrumb
	},

	computed: {
		breadCrumbItems() {
			return [
				{ text: 'الدورات', to: null },
				{ text: 'دورة', to: { name: 'courses' } }
			]
		}
	},
	
	metaInfo: {
		title: 'Courses',
		titleTemplate: '%s | New Horizons'
	}
}
</script>

<style>

</style>