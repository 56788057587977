import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
	rtl: true,
	theme: {
		themes: {
			light: {
				primary: '#084566',
				secondary: '#a7a7a7',
				success: '#13a689',
				error: '#ff3334',
				'error-light': '#be7386',
			},

			dark: {}
		}
	}
})
