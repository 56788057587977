import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import UserInfo from '../views/UserInfo.vue'
import MyCourses from '../views/courses/MyCourses.vue'
import Results from '../views/courses/Results.vue'
import Exams from '../views/courses/Exams.vue'
import Courses from '../views/courses/Courses.vue'
import AboutCenter from '../views/AboutCenter.vue'

export default [
	{
		path: '/',
		name: 'home',
		component: Home
	},
	{
		path: '/login',
		name: 'login',
		component: Login
	},
	{
		path: '/user-info',
		name: 'user-info',
		component: UserInfo
	},
	{
		path: '/my-courses',
		name: 'my-courses',
		component: MyCourses
	},
	{
		path: '/operations/:id',
		name: 'results',
		component: Results
	},
	{
		path: '/exams/:id',
		name: 'exams',
		component: Exams
	},
	{
		path: '/courses',
		name: 'courses',
		component: Courses
	},
	{
		path: '/about-center',
		name: 'about-center',
		component: AboutCenter
	},
]