<template>
	<div class="login">
		<v-container>
			<v-layout align-center justify-center>
				<v-flex md4 sm8 xs11>
					<v-form @submit.prevent="login" ref="form">
						<v-card shaped>
							<v-card-title class="py-1">
								<v-spacer></v-spacer>
								<v-img
									alt="new horizons logo"
									class="shrink"
									contain
									src="../assets/logo.png"
									width="120"
								/>
								<v-spacer></v-spacer>
							</v-card-title>

							<v-card-text>
								<v-row>
									<v-col cols="12">
										<v-text-field
											v-model="username"
											label="اسم المستخدم"
											hide-details="auto"
											:disabled="loading"
											:rules="rules.required"
										></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field
											v-model="nhId"
											label="رقم الطالب"
											hide-details="auto"
											:disabled="loading"
											:rules="rules.required"
										></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field
											v-model="password"
											label="كلمة المرور"
											hide-details="auto"
											type="password"
											:disabled="loading"
											:rules="rules.required"
										></v-text-field>
									</v-col>
								</v-row>
							</v-card-text>

							<v-card-actions class="pt-4">
								<v-btn
									color="primary"
									type="submit"
									:loading="loading"
									:disabled="loading"
									class="shaped-bottom rounded-tl-0"
									width="100%"
								>
									تسجيل الدخول
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-form>
					<v-alert
						:value="error != null"
						v-if="!loading && error"
						shaped
						dense
						type="error"
						class="mt-3"
						transition="scale-transition"
					>{{ error }}</v-alert>
				</v-flex>
			</v-layout>
		</v-container>
		<div dir="ltr" class="poweredBy">
			<p class="text-center  white--text mb-0">
				<span class=" text-overline">Powered by </span>
				<a
					target="_blank"
					href="https://www.beetronix.com"
					class="font-weight-bold white--text text-decoration-none"
					style="letter-spacing: 2px"
				>Beetronix</a>
			</p>
		</div>

		<!-- password -->
		<bee-dialog
			v-model="passwordFirstChange.dialog"
			:loading="passwordFirstChange.loading"
			:persistent="true"
			:hide-cancel-btn="true"
			width="400"
			title="تغيير كلمة المرور"
			@confirm="changePassword"
		>
			<v-form ref="changePasswordForm">
				<v-row class="justify-center mt-1">
					<v-col cols="12" class="py-1">
						<v-text-field
							v-model="passwordFirstChange.value"
							label="كلمة المرور الجديدة"
							type="password"
							:rules="rules.password"
							@keypress.enter="changePassword"
						></v-text-field>
					</v-col>
					<v-col cols="12" class="py-1">
						<v-text-field
							v-model="passwordFirstChange.confirm"
							label="تأكيد كلمة المرور"
							type="password"
							:rules="[val => passwordFirstChange.value === passwordFirstChange.confirm || 'كلمة السر غير مطابقة']"
							@keypress.enter="changePassword"
						></v-text-field>
					</v-col>
				</v-row>
			</v-form>
		</bee-dialog>
	</div>
</template>

<script>
import rules from '../validation rules'
export default {
	name: 'Login',

	data: () => ({
		rules,

		nhId: null,
		username: null,
		password: null,
		loading: null,
		error: null,

		passwordFirstChange: {
			value: null,
			confirm: null,
			dialog: false,
			loading: false,
		}
	}),
	
	methods: {
		login() {
			if (this.$refs.form.validate()) {
				this.loading = true;
				this.$store.dispatch('auth/login', {
					nhId: this.nhId,
					username: this.username,
					password: this.password,
				}).then((data) => {
					const status = Number(data.status);
					if (status >= 200 && status < 300 && data.error_code !== 'change_password') {
						this.$router.replace({ name: 'home' }).then(() => {
							this.$store.dispatch('student/fetchProfile')
						})
					} else if (status === 205 && data.error_code === 'change_password') {
						this.$store.state.auth.authenticated = false;
						localStorage.removeItem('auth');
						this.passwordFirstChange.dialog = true;
						this.$emit('snackbar:on', 'لابد من تغيير كلمة المرور للدخول إلى التطبيق');
					} else if (status === 403 && data.error_code === 'wrong_credentials') {
						this.error = 'هناك خطأ في المدخلات'
					}
				}).finally(() => {
					this.loading = false;
					this.passwordFirstChange.loading = false;
				})
			}
		},

		changePassword() {
			if (this.$refs.changePasswordForm.validate()) {
				this.passwordFirstChange.loading = true;
				this.$store.dispatch('student/updatePassword', {
					password: this.passwordFirstChange.value
				}).then((data) => {
					if (Number(data.status) === 200) {
						this.password = this.passwordFirstChange.value;
						this.login();
					} else {
						this.passwordFirstChange.loading = false;
						this.$emit('snackbar:on', 'حدث خطأ ما');
					}
				}).catch(() => {
					this.passwordFirstChange.loading = false;
				})
			}
		},
	},

	metaInfo: {
		title: 'Login',
		titleTemplate: '%s | New Horizons'
	}
}
</script>

<style lang="scss" scoped>
.login {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	background-image:  url('../assets/earth-wallpaper.jpg');
	background-position: center;
	background-size: cover;
}
.poweredBy{
	position: absolute;
	bottom: 2px;

	display: flex;
	justify-content: center;
	align-items: center;

	font-size: 14px;
	filter: drop-shadow(0px 3px 3px black);
}
</style>