import * as types from '../mutations-types'
import md5 from 'md5';

export default {
	namespaced: true,

	state: {
		profile: {},
		numbers: [],
		courses: [],
		coursesOperations: [],
		coursesMarks: [],

		coursesData: []
	},

	actions: {
		fetchProfile({ commit }) {
			return axios.get('/me').then((response) => {
				commit(types.STORE_PROFILE, response.data)
				return response.data;
			})
		},
		fetchNumbers({ commit }) {
			return axios.get('/me/numbers').then((response) => {
				commit(types.STORE_NUMBERS, response.data)
				return response.data;
			})
		},
		fetchCourses({ commit }) {
			return axios.get('/me/courses').then((response) => {
				commit(types.STORE_COURSES, response.data)
				return response.data;
			})
		},
		fetchCoursesOperations({ commit }) {
			return axios.get('/me/courses/operations').then((response) => {
				commit(types.STORE_COURSES_OPERATIONS, response.data)
				return response.data;
			})
		},
		fetchCoursesMarks({ commit }) {
			return axios.get('/me/courses/marks').then((response) => {
				commit(types.STORE_COURSES_MARKS, response.data)
				return response.data;
			})
		},
		fetchCoursesData({ state, dispatch, commit }) {
			return Promise.all([
				dispatch('fetchCourses'),
				dispatch('fetchCoursesOperations'),
				dispatch('fetchCoursesMarks'),
			]).then(() => {
				const coursesData = [];
				state.courses.forEach(course => {
					coursesData.push({
						...course,
						operations: state.coursesOperations.filter(c => c.course_ids === course.ids),
						marks: state.coursesMarks.filter(c => c.course_ids === course.ids),
					})
				});
				commit(types.STORE_COURSES_DATA, coursesData);
				return coursesData;
			})
		},

		updateProfile({ commit }, { birthday, address, work, company, university, faculty, speciality, gender, email, facebook }) {
			return axios.post('/me/edit', {
				...fixedPostData, birthday, address, work, company, university, faculty, speciality, email, gender, facebook
			}).then((response) => {
				return response.data;
			})
		},

		updatePassword({ commit }, { password }) {
			return axios.post('/password/first-change', {
				...fixedPostData,
				new_password: md5(password)
			}).then((response) => {
				return response.data;
			})
		},

		uploadImage({ commit }, { image }) {
			var formData = new FormData();
			formData.append('image', image);
			Object.keys(fixedPostData).forEach(key => {
				formData.append(key, fixedPostData[key]);
			})
			return axios.post('/me/image', formData).then((response) => {
				return response.data;
			})
		},

		createNumber({ commit }, { number }) {
			return axios.post('/numbers', {
				...fixedPostData, number, type_index: 0
			}).then((response) => {
				return response.data;
			})
		},
		updateNumber({ commit }, { numberId, newNumber }) {
			return axios.post('/numbers/edit', {
				...fixedPostData, number_id: numberId, new_number: newNumber, type_index: 0
			}).then((response) => {
				return response.data;
			})
		},
		deleteNumber({ commit }, { numberId }) {
			return axios.post('/numbers/delete', {
				...fixedPostData, number_id: numberId
			}).then((response) => {
				return response.data;
			})
		}
	},

	mutations: {
		[types.STORE_PROFILE](state, profile) {
			state.profile = profile;
		},
		[types.STORE_NUMBERS](state, numbers) {
			state.numbers = numbers;
		},
		[types.STORE_COURSES](state, courses) {
			state.courses = courses;
		},
		[types.STORE_COURSES_OPERATIONS](state, coursesOperations) {
			state.coursesOperations = coursesOperations;
		},
		[types.STORE_COURSES_MARKS](state, coursesMarks) {
			state.coursesMarks = coursesMarks;
		},
		[types.STORE_COURSES_DATA](state, coursesData) {
			state.coursesData = coursesData;
		}
	}
}