import * as types from '../mutations-types'
import md5 from 'md5'
import { encode } from 'js-base64'

export default {
	namespaced: true,

	state: {
		authenticated: false,
	},

	actions: {
		login({ commit }, { nhId, username, password }) {
			return axios.post('/login', {
				nh_id: nhId,
				user_name: username,
				password: md5(password),
				current_version_code: 3
			}).then((response) => {
				if (response.data.status >= 200 && response.data.status < 300) {
					const auth = { nhId, username, password };
					commit(types.LOGIN, auth);
				}
				return response.data;
			})
		},
		logout({ commit }) {
			commit(types.LOGOUT);
		},
	},

	mutations: {
		[types.LOGIN](state, auth) {
			state.authenticated = true;
			localStorage.setItem('auth', JSON.stringify(auth));
			axios.defaults.headers.common['x-device-guid'] = auth.nhId;
			axios.defaults.headers.common['x-nh-id'] = auth.nhId;
			axios.defaults.headers.common['x-password'] = md5(auth.password);
			axios.defaults.headers.common['x-username'] = encode(auth.username);
			fixedPostData = {
				nh_id: auth.nhId,
				password: md5(auth.password),
				current_version_code: 3
			}
		},
		[types.LOGOUT](state) {
			state.authenticated = false;
			localStorage.removeItem('auth');
			delete axios.defaults.headers.common['x-device-guid'];
			delete axios.defaults.headers.common['x-nh-id'];
			delete axios.defaults.headers.common['x-password'];
			delete axios.defaults.headers.common['x-username'];
			fixedPostData = null;
		},
	}
}