<template>
	<v-app-bar app color="white" class="px-xs-5 elevation-2" height="70">
		<v-btn
			text
			icon
			large
			color="primary"
			@click="$emit('change-drawer')"
		>
			<v-icon>mdi-menu</v-icon>
		</v-btn>

        <v-spacer></v-spacer>
		<v-img
			alt="new horizons logo"
			class="shrink cursor-pointer"
			:class="{'me-9': !showBackButton}"
			contain
			src="../assets/logo.png"
			transition="scale-transition"
			width="120"
            @click="$route.name !== 'home' ? $router.push({ name: 'home' }) : null"
		/>
		<v-spacer></v-spacer>
		
		<v-btn
			v-if="showBackButton"
			text
			icon
			large
			color="primary"
			@click="$router.history.go(-1)"
		>
			<v-icon>mdi-chevron-left</v-icon>
		</v-btn>
	</v-app-bar>
</template>

<script>
export default {
	name: 'Navbar',

	data() {
		return {
			selectedItem: -1,
			items: [{ title: 'Logout' }],
			showBackButton: false,
			loading: false,
		};
	},

	watch: {
		'$route.name'() {
			if (window.history.length > 2 && this.$route.name !== 'home') {
				this.showBackButton = true
			} else {
				this.showBackButton = false;
			}
		}
	}
};
</script>

<style lang="scss">
</style>