// this is validations rules for vuetify component validation
export default {
	required: [val => (val || '').length > 0 || 'هذا الحقل مطلوب'],
	name: [val => (val || '').length > 0 || 'This field is required'],
	number: [val => (val >= 0 && val !== null) || 'This number should be non-negative.'],
	requiredAsNumber: [val => (val >= 0 && val !== null) || 'This field is required'],
	url: [
		val => {
			const regexp = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gim;
			return regexp.test(val) || 'Invalid URL.'
		}
	],
	date: [
		val => {
			const date = /^(19|20)[0-9]{2}[-](1[0-2]|0[1-9])[-](0[1-9]|(1|2)[0-9]|3(0|1))$/;
			return date.test(val) || 'Invalid date. Date format is \'yyyy-mm-dd\'.'
		}
	],
	year: [
		val => {
			const date = /^(19|20)[0-9]{2}$/;
			return date.test(val) || 'Invalid year.'
		}
	],
	month: [val => (val >= 1 && val <= 12) || 'Invalid month.'],
	quarter: [val => (val >= 1 && val <= 4) || 'must be between 1-4.'],
	password: [val => (val || '').length >= 8 || 'كلمة السر على الأقل 8 رموز'],
	mobile: [val => {
		let lengthIsValid;
		const value = (val || '');
		if (
			((value[0] === '+' && value.length === 13)) ||
			(value.slice(0, 2) === '00' && value.length === 14) ||
			value.length === 10
		) {
			lengthIsValid = true;
		}
		return (lengthIsValid && /((\+|00)[0-9]{12})|(0[1-9][0-9]{8})/g.test(value)) || 'رقم هاتف غير صالح'
	}],
	email: [
		val => {
			const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			return pattern.test(val) || 'Invalid e-mail.'
		}
	],
}