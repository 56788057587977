<template>
	<v-navigation-drawer
		v-model="localValue"
		app
		dark
		color="primary"
		:mini-variant="$vuetify.breakpoint.mdAndUp && !localValue"
		:permanent="$vuetify.breakpoint.mdAndUp"
		:expand-on-hover="$vuetify.breakpoint.mdAndUp && !localValue"
		right
	>
		<v-list nav>
			<!-- home -->
			<v-list-item :to="{name: 'user-info'}" exact class="ma-0 py-2">
				<v-list-item-icon class="my-0 me-4">
					<v-avatar size="48" color="secondary">
						<v-img v-if="profile.pic_link" :src="profile.pic_link" :alt="profile.name_en"></v-img>
						<img v-else src="@/assets/user.png" :alt="profile.name_en">
					</v-avatar>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>
						{{profile.name_en ? profile.name_ar : '-'}}
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-divider class="my-2"></v-divider>

			<v-list-item :to="{ name: 'home' }" exact>
				<v-list-item-icon>
					<v-icon>mdi-home</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>الرئيسية</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item :to="{ name: 'my-courses' }" exact>
				<v-list-item-icon>
					<v-icon>mdi-bookshelf</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title class="pb-1">دوراتي</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item disabled :to="{ name: 'courses' }" exact>
				<v-list-item-icon>
					<v-icon>mdi-school-outline</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>الدورات</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item :to="{ name:'about-center' }" exact>
				<v-list-item-icon>
					<v-icon>mdi-information-outline</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>عن المركز</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-list-item @click="logout">
				<v-list-item-icon>
					<v-icon>mdi-logout</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>تسجيل الخروج</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>

		<span
			class="d-block white--text text-body-1 font-weight-light ps-4 pb-1"
			style="width: 160px; font-size: 12px !important"
			v-if="localValue"
		>
			بواسطة شركة
			<a
				target="_blank"
				href="https://www.beetronix.com"
				class="font-weight-bold warning--text text-decoration-none"
			>بيترونكس</a>
		</span>
	</v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'NavigationDrawer',

	props: {
		value: { type: Boolean, default: false }
	},

	computed: {
		localValue: {
			get() {
				return this.value;
			},

			set(newVal) {
				this.$emit('input', newVal);
			}
		},

		...mapState({
			profile: state => state.student.profile
		})
	},

	methods: {
		logout() {
			this.$store.dispatch('auth/logout');
			this.$router.replace({ name: 'login' });
			this.$emit('input', false);
		}
	}
};
</script>

<style>
.active-group {
	border-left: 3px solid #13a689;
	background-color: #094174;
}
.group-item {
	background-color: #094174;
}
.v-navigation-drawer__content {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
</style>