<template>
	<div>
		<h1 class="primary--text text-center text-h6 mb-4">الامتحانات</h1>
		<transition name="coursesData.legnth > 0" mode="out-in">
			<v-row class="mt-0" v-if="coursesData.length > 0">
				<v-col v-for="mark in marks" :key="mark.ids" md="4" sm="6" cols="12" class="in-animation py-0">
					<v-card shaped class="mb-3">
						<v-card-text class="pa-2">
							<div class="d-flex justify-space-between text-body-1">
								<span class="ms-8">{{mark.type}}</span>
								<span class="me-8">
									<span class="primary--text">{{mark.value}}</span>
									<span class="font-weight-bold"> / {{mark.max_value}}</span>
								</span>
							</div>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>

			<div
				v-else
				class="d-flex flex-column justify-center align-center mt-15"
				:class="{'pulse': navLoading}"
			>
				<v-img
					min-width="120"
					width="25%"
					max-width="150"
					contain
					src="@/assets/test.png"
					transition="scale-transition"
				></v-img>
				<p v-if="!navLoading" class="mt-3 mb-0 text-subtitle-1 font-weight-bold">لا يوجد نتائج</p>
			</div>
		</transition>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Exams',

	props: {
		navLoading: { type: Boolean }
	},

	computed: {
		marks() {
			return this.coursesData.find(c => c.ids === Number(this.$route.params.id))?.marks ?? null;
		},
		...mapState({
			coursesData: state => state.student.coursesData
		})
	},
	
	metaInfo: {
		title: 'Exams',
		titleTemplate: '%s | New Horizons'
	},
}
</script>