<template>	
	<div class="d-flex flex-column justify-center align-center height-100">
		<!-- user -->
		<div class="d-flex flex-column align-center cursor-pointer" @click="$router.push({ name: 'user-info' })">
			<v-avatar size="120" color="secondary">
				<!-- <img src="https://images.unsplash.com/photo-1629709200392-f3051760e529?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=120&q=120" alt="alt"> -->
				<v-img v-if="profile.pic_link" :src="profile.pic_link" :alt="profile.name_en"></v-img>
				<img v-else src="@/assets/user.png" :alt="profile.name_en">
			</v-avatar>
			<h1 class="text-body-1 font-weight-bold text-center mb-4">
				<div class="my-2">{{profile.name_ar ? profile.name_ar : '-'}}</div>
				<div>{{profile.name_en ? profile.name_en : '-'}}</div>
			</h1>
		</div>

		<!-- btns -->
		<div class="text-center">
			<v-btn :to="{ name: 'my-courses' }" color="primary" width="65%" class="text-body-1 mb-5">
				<v-icon class="ms-3">mdi-bookshelf</v-icon>
				<span class="flex-grow-1 me-8">دوراتي</span>
			</v-btn>
			<v-btn disabled :to="{ name: 'courses' }" color="primary" width="65%" class="text-body-1 mb-5">
				<v-icon class="ms-3">mdi-school-outline</v-icon>
				<span class="flex-grow-1 me-8">الدورات</span>
			</v-btn>
			<v-btn :to="{ name: 'about-center' }" width="65%" class="text-body-1 mb-5">
				<v-icon class="ms-3">mdi-information-outline</v-icon>
				<span class="flex-grow-1 me-8">عن المركز</span>
			</v-btn>
		</div>

		<!-- bottom -->
		<v-bottom-navigation absolute height="70" horizontal>
			<v-spacer></v-spacer>
			<v-img
				alt="new horizons logo"
				class="shrink cursor-pointer"
				contain
				src="../assets/charter.png"
				transition="scale-transition"
				width="120"
				@click="$route.name !== 'home' ? $router.push({ name: 'home' }) : null"
			/>
			<v-spacer></v-spacer>
		</v-bottom-navigation>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Home',

	computed: {
		...mapState({
			profile: state => state.student.profile
		})
	},

	metaInfo: {
		title: 'Home',
		titleTemplate: '%s | New Horizons'
	}
};
</script>