<template>
	<div>
		<h1 class="primary--text text-center text-h6 mb-4">عن المركز</h1>
		<div class="d-flex justify-space-around mb-5">
			<v-img
				src="@/assets/charter.png"
				:max-width="$vuetify.breakpoint.mdAndUp ? '15%' : '25%'"
				transition="scale-transition"
				contain
			/>
			<v-img
				src="@/assets/logo.png"
				:max-width="$vuetify.breakpoint.mdAndUp ? '25%' : '35%'"
				transition="scale-transition"
				contain
			/>
		</div>

		<!-- contect us -->
		<v-card :value="0" class="mb-5">
			<v-card-title expand-icon hide-actions class="pb-6">
				<v-spacer></v-spacer>
				<span class="text-body-1">معلومات التواصل</span>
				<v-spacer></v-spacer>
			</v-card-title>
			<v-card-text>
				<div class="d-flex flex-wrap justify-space-around">
					<div class="d-flex py-1">
						<v-spacer></v-spacer>
						<span dir="auto">+963-988-002999</span>
						<v-spacer></v-spacer>
						<v-icon color="primary" class="ms-1">mdi-cellphone</v-icon>
					</div>
					<div class="d-flex py-1">
						<v-spacer></v-spacer>
						<span dir="auto">+963-21-9321</span>
						<v-spacer></v-spacer>
						<v-icon color="primary" class="ms-1">mdi-phone-classic</v-icon>
					</div>
					<div class="d-flex py-1">
						<v-spacer></v-spacer>
						<span dir="auto">+963-21-2689568</span>
						<v-spacer></v-spacer>
						<v-icon color="primary" class="ms-1">mdi-fax</v-icon>
					</div>
				</div>
			</v-card-text>
		</v-card>

		<h2 class="primary--text text-center text-h6">شركاؤنا</h2>
		<div class="d-flex flex-wrap justify-center">
			<v-img
				src="@/assets/edexcel.png"
				:max-width="$vuetify.breakpoint.mdAndUp ? '12%' : '100px'"
				transition="scale-transition"
				contain
				class="my-2 mx-5"
			/>
			<v-img
				src="@/assets/pmi.png"
				:max-width="$vuetify.breakpoint.mdAndUp ? '12%' : '100px'"
				transition="scale-transition"
				contain
				class="my-2 mx-5"
			/>
			<v-img
				src="@/assets/microsoft.png"
				:max-width="$vuetify.breakpoint.mdAndUp ? '12%' : '100px'"
				transition="scale-transition"
				contain
				class="my-2 mx-5"
			/>
			<v-img
				src="@/assets/java.png"
				:max-width="$vuetify.breakpoint.mdAndUp ? '12%' : '100px'"
				transition="scale-transition"
				contain
				class="my-2 mx-5"
			/>
			<v-img
				src="@/assets/cisco.png"
				:max-width="$vuetify.breakpoint.mdAndUp ? '12%' : '100px'"
				transition="scale-transition"
				contain
				class="my-2 mx-5"
			/>
		</div>
	</div>
</template>

<script>
export default {
	name: 'AboutCenter',
	
	metaInfo: {
		title: 'About center',
		titleTemplate: '%s | New Horizons'
	}
}
</script>

<style>

</style>