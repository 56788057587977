<template>
	<v-app>
		<navbar v-if="$route.name !== 'login'" @change-drawer="drawer =! drawer"></navbar>
		<navigation-drawer v-if="$route.name !== 'login'" v-model="drawer"></navigation-drawer>

		<v-main v-if="$route.name !== 'login'">
			<v-progress-linear v-if="navLoading || loading" height="3" absolute top indeterminate></v-progress-linear>
			<v-container :class="!['home'].includes($route.name) ? 'main-content' : 'main-content-with-sheet'">
				<transition appear mode="out-in" name="fade">
					<router-view
						:navLoading="navLoading || loading"
						@nav-loader:on="navLoading = true"
						@nav-loader:off="navLoading = false"
						@snackbar:on="message = $event; snackbar = true"
						@snackbar:off="snackbar = false"
					/>
				</transition>
			</v-container>
		</v-main>

		<v-main v-else>
			<router-view
				@snackbar:on="message = $event; snackbar = true"
				@snackbar:off="snackbar = false; message= null"
			/>
		</v-main>

		<!-- snackbar -->
		<v-snackbar
			v-model="snackbar"
			shaped
			transition="slide-y-reverse-transition"
			color="primary"
		>
			{{ message }}

			<template v-slot:action="{ attrs }">
				<v-btn
					color="white"
					icon
					v-bind="attrs"
					@click="snackbar = false; message = null"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
	</v-app>
</template>

<script>
import Navbar from './components/Navbar.vue'
import NavigationDrawer from './components/NavigationDrawer.vue'
import { mapState } from 'vuex';
export default {
	name: 'App',
	
	components: {
		Navbar,
		NavigationDrawer
	},

	data: () => ({
		drawer: false,
		loading: true,
		navLoading: false,
		snackbar: null,
		message: null,
	}),

	watch: {
		'$route.name'() {
			this.fetchCoursesData();
		},
		watchData(val) {
			const values = val.split('|');
			if (values[0] && values[1].length > 0) {
				this.loading = false;
				this.fetchCoursesData();
			}
		}
	},

	computed: {
		watchData() {
			return `${this.authenticated}|${Object.keys(this.profile)}`
		},

		...mapState({
			authenticated: state => state.auth.authenticated,
			coursesData: state => state.student.coursesData,
			profile: state => state.student.profile
		})
	},

	methods: {
		fetchCoursesData() {
			if (
				['my-courses', 'results', 'exams'].includes(this.$route.name) &&
				this.coursesData.length === 0
			) {
				this.navLoading = true;
				this.$store.dispatch('student/fetchCoursesData').finally(() => {
					this.navLoading = false;
				})
			}
		}
	}
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;500;600;700&display=swap');
@import 'scss/vuetify-overrides.scss';
* {
	font-family: 'Cairo', sans-serif;
}
body {
	overflow-x: hidden;
}
.main-content {
	// height: 85vh;
}
.main-content-with-sheet {
	height: 75vh;
}

::-webkit-scrollbar {
	background: #eee;
	width: 6px;
}
::-webkit-scrollbar-thumb {
	background: #bbb;
	border-radius: 30px;
}

/* page transations */
.fade-enter-active, .fade-leave-active {
  transition: all .4s;
}
.fade-leave-to {
	opacity: 0;
	transform: rotateY(-90deg);
}
.fade-enter {
	opacity: 0;
	transform: rotateY(90deg);
}

.loading-enter-active, .loading-leave-active {
  transition: all .1s;
}
.loading-leave-to, .loading-enter {
	opacity: 0;
}

/* helpers */
.height-100 {
	height: 100%;
}
.cursor-pointer {
	cursor: pointer;
}

.shaped {
	&-all {
		border-radius: 24px 0 !important;
	}
	&-top {
		border-top-left-radius: 24px !important;
	}
	&-bottom {
		border-bottom-right-radius: 24px !important;
	}
}

.in-animation {
	&:nth-of-type(n) {
		animation: slide-right .6s .3s cubic-bezier(.32,.35,.54,1) forwards;
		transform: translateX(100vw);
	}
	&:nth-of-type(2n) {
		animation: slide-left .6s .3s cubic-bezier(.32,.35,.54,1) forwards;
		transform: translateX(-100vw);
	}

	@keyframes slide-right {
		from {
			transform: translateX(-100vw);
		}
		to {
			transform: translateX(0);
		}
	}
	@keyframes slide-left {
		from {
			transform: translateX(100vw);
		}
		to {
			transform: translateX(0);
		}
	}
}

.pulse {
	animation: pluse 1.2s ease-in-out infinite;
	@keyframes pluse {
		0% {
			opacity: 0.5;
		}
		50% {
			opacity: 1;
		}
		100% {
			opacity: 0.5;
		}
	}
}
</style>
